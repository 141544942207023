<template>

  <div id="app">

    <h1 id="head">欢迎来到浪星的小屋</h1>

    <div class="content">
      <div style="margin-bottom: 20px;margin-top: 20px" class="flex-ac">
        <upload ref="upload" @change="file => form.file = file"></upload>
        <el-button style="margin-left: 10px;" type="primary" @click="uploadImg" :disabled="uploading">单文件上传</el-button>
      </div>

    </div>


  </div>

</template>

<script>


import acct from "@/api/acct";
import request from "@/mixins/request";
import upload from "@/components/upload";
import {saveAs} from 'file-saver';

export default {
  mixins: [request],
  name: 'app',
  components: {
    // HelloWorld
    upload
  },
  mounted() {

  },
  data() {
    return {
      form: {
        file: null,
        zipFile: null,
      },
      content: "",
      resp: "",
      uploading: false,
    }
  },
  created() {

  },
  methods: {
    save() {
      if (this.resp === '') {
        this.$message.success("无内容");
        return;
      }
      let str = new Blob([this.resp], {type: 'text/plain;charset=utf-8'});
      saveAs(str, '乱码修复.txt');
      // 注意这里要手动写上文件的后缀名
    },
    copy_result() {
      this.$message.success("内容已复制到剪切板！")
    },
    onError() {
      this.$message.error("抱歉，复制失败！")
    },
    // uploadZip() {
    //   const file = this.form.zipFile;
    //   if (!this.beforeUpload(file)) {
    //     return;
    //   }
    //   console.log(file.type)
    //   this.uploading = true
    //   return acct.uploadTempFile(file)
    //       .then(() => {
    //             this.$message.success('上传成功')
    //           }
    //       ).catch(() => {
    //         this.$message.warning('上传失败')
    //       })
    //       .then(() => {
    //         this.uploading = false
    //       })
    // },
    beforeUpload(file) {
      if (!file) {
        this.$message.warning("请先选择文件")
        return false;
      }
      return true;
    },
    uploadImg() {
      if (!this.beforeUpload(this.form.file)) {
        return;
      }
      this.uploading = true
      console.log()
      return acct.uploadTempFile(this.form.file)
          .then(res => {
                console.log("请求成功" + res)
                console.log(res.content);
                this.resp = res.content;
                this.$message.success('请求成功')
              }
          ).catch(() => {
            this.$message.warning('上传失败')
          })
          .then(() => {
            this.uploading = false
          })
    },

  }
}

// import FreeStar from "@/components/FreeStar";

// export default {
//   name: 'App',
//   components: {
//     FreeStar
//   }
// }


// new Vue({
//   el: '#app2',
//   data: {message: 'hello freestar'}
// })
// new Vue({
//   el: '#app',
//   data: {
//     msg: 'Hello freestar!'
//   }
// })
</script>

<style>

.textContent {
  width: 100%;
  height: 80%;
}

.btn {
  margin-left: 10%;
}

.btnContainer {
  /*text-align: center;*/
  text-align: center;
}

.flex-ac {
  display: flex;
  align-items: center;
}


.content {
  width: 70%;
  margin-left: 5%;
}

#head {
  text-align: -webkit-center;
}


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  /*margin-top: 10px;*/
}
</style>
