<template>

  <div id="app">

    <h1 id="head">欢迎来到浪星的小屋</h1>
    <!--    <img alt="Vue logo" src="./assets/logo.png">-->
    <!--        <HelloWorld msg="Welcome to Your Vue.js App"/>-->
    <!--    <FreeStar message="welcome to freestar house"/>-->

    <!--    <p>Hahahahha</p>-->

    <h3 class="content">中文乱码修复</h3>

    <div class="content">
      <!--      <textarea class="textContent" placeholder="请输入内容" v-model="content" cols="10" rows="10"/>-->

      <!--      <div class="btnContainer">-->
      <!--        &lt;!&ndash;        <input type="button">GBK 转 UTF-8&ndash;&gt;-->
      <!--        <input type="button" @click="transform(1)" value="GBK 转 UTF-8">-->
      <!--        <button class="btn" @click="my_copy" type="button">复制</button>-->
      <!--        <input class="btn" type="button" @click="transform(2)" value="UTF-8 转 GBK">-->

      <!--        &lt;!&ndash;              <el-button type="primary">GBK 转 UTF-8</el-button>&ndash;&gt;-->
      <!--        &lt;!&ndash;              <el-button type="primary" class="btn">复制</el-button>&ndash;&gt;-->
      <!--        &lt;!&ndash;              <el-button type="primary" class="btn">UTF-8 转 GBK</el-button>&ndash;&gt;-->
      <!--      </div>-->
      <!--      <div>freestar+{{ this.content }}</div>-->
      <!--      <el-row>-->
      <!--        &lt;!&ndash;      <input type="button" >GBK 转 UTF-8&ndash;&gt;-->
      <!--        &lt;!&ndash;        <input type="button" value="GBK 转 UTF-8">&ndash;&gt;-->
      <!--        &lt;!&ndash;        <input class="btn" type="button" value="复制">&ndash;&gt;-->
      <!--        &lt;!&ndash;        <input class="btn" type="button" value="UTF-8 转 GBK">&ndash;&gt;-->

      <!--        <el-button type="primary">GBK 转 UTF-8</el-button>-->
      <!--        <el-button type="primary" class="btn">复制</el-button>-->
      <!--        <el-button type="primary" class="btn">UTF-8 转 GBK</el-button>-->
      <!--      </el-row>-->

      <div style="margin-bottom: 20px;margin-top: 20px" class="flex-ac">
        <upload ref="upload" @change="file => form.file = file"></upload>
        <el-button style="margin-left: 10px;" type="primary" @click="uploadImg" :disabled="uploading">单文件上传</el-button>
      </div>

      <textarea id="result" class="textContent" disabled v-model="resp" cols="10" rows="20"/>

      <el-button type="primary" v-clipboard:copy="resp" v-clipboard:success="copy_result"
                 v-clipboard:error="onError" class="btn">复制结果
      </el-button>

      <el-button type="primary" class="btn" @click="save">保存成文件</el-button>

    </div>

    <h3 class="content">多文件、压缩包方式</h3>

    <div class="content">
      <div style="margin-bottom: 20px;margin-top: 20px" class="flex-ac">
        <upload ref="upload" @change="file => form.zipFile = file"></upload>
        <el-button style="margin-left: 10px;" type="primary" @click="uploadZip" :disabled="uploading">zip 压缩包上传
        </el-button>
      </div>
    </div>

    <!--    <div>-->
    <!--      <el-upload-->
    <!--          class="upload-demo"-->
    <!--          drag-->
    <!--          action="https://jsonplaceholder.typicode.com/posts/"-->
    <!--          multiple>-->
    <!--        <i class="el-icon-upload"></i>-->
    <!--        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>-->
    <!--        <template #tip>-->
    <!--          <div class="el-upload__tip">-->
    <!--            只能上传 jpg/png 文件，且不超过 500kb-->
    <!--          </div>-->
    <!--        </template>-->
    <!--      </el-upload>-->
    <!--    </div>-->
    <!--    <div>-->
    <!--      <upload  ref="upload" @change="uploadChange"></upload>-->
    <!--    </div>-->

    <!--    <div class="botCenter">-->
    <!--      &lt;!&ndash; <p>&copy;Copyright - Medicio Theme. All rights reserved.</p> &ndash;&gt;-->
    <!--      <a href="http://beian.miit.gov.cn/" target="view_window"-->
    <!--         style="text-align: center;display: block;">浙ICP备20003888号-2</a>-->
    <!--    </div>-->



  </div>

</template>

<script>


import acct from "@/api/acct";
import request from "@/mixins/request";
import upload from "@/components/upload";
import {saveAs} from 'file-saver';

export default {
  mixins: [request],
  name: 'app',
  components: {
    // HelloWorld
    upload
  },
  mounted() {

  },
  data() {
    return {
      form: {
        file: null,
        zipFile: null,
      },
      content: "",
      resp: "",
      uploading: false,
    }
  },
  created() {

    // window.location.href = "http://qrcode.ayouayou.com/qrcode/1143790528405266433.png";
    // window.location.href = "http://qrcode.ayouayou.com/qrcode/temp/233.zip";
    // acct.sectionList()
    //     // .then(() => {
    //     //   console.log("请求成功")
    //     //   // this.$message.success('密码重置成功')
    //     // })
    //     .then(res => {
    //       console.log("请求成功" + res)
    //       // this.status = this.$formatList(res.data['充值状态枚举'])
    //       // this.payChannel = this.$formatList(res.data['支付方式枚举'], 'value', 'label', false)
    //     })
    //     .catch(this.requestError)
    //     .finally(() => {
    //       console.log("finally")
    //       // this.loading = false
    //     })

    // this.$confirm('下载转换文件?', '提示', {
    //   confirmButtonText: '确定',
    //   cancelButtonText: '取消',
    //   type: 'warning'
    // }).then(() => {
    //   // window.location.href = "http://qrcode.ayouayou.com/qrcode/temp/233.zip";
    //   let a = document.createElement('a')
    //   a.href = "http://test.jynmed.com/export/excel/caseAnalysis/serviceLevelReport?department=&endAt=&fields=CMI,总权重,病案总例数,DRG组数,入组率,重症占比,CC/MCC占比,轻症占比"
    //   a.click();
    // }).catch(() => {
    //   this.$message({
    //     type: 'info',
    //     message: '已取消'
    //   });
    // });

  },
  methods: {
    save() {
      if (this.resp === '') {
        this.$message.success("无内容");
        return;
      }
      let str = new Blob([this.resp], {type: 'text/plain;charset=utf-8'});
      saveAs(str, '乱码修复.txt');
      // 注意这里要手动写上文件的后缀名
    },
    copy_result() {
      this.$message.success("内容已复制到剪切板！")
    },
    onError() {
      this.$message.error("抱歉，复制失败！")
    },
    uploadZip() {
      const file = this.form.zipFile;
      if (!this.beforeUpload(file)) {
        return;
      }
      console.log(file.type)
      if (file.type !== 'application/zip') {
        this.$message.error('文件必须是 zip 压缩包');
        return;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error('压缩包大小不能超过 2MB!');
        return;
      }
      this.uploading = true
      return acct.uploadFile(file)
          .then(res => {
                console.log(res.downloadPath);
                // this.$alert('下载转换文件', '下载', {
                //   confirmButtonText: '确定',
                //   callback: () => {
                //     window.location.href = "http://qrcode.ayouayou.com/qrcode/temp/233.zip";
                //   }
                // });
                this.$confirm('下载转换文件?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  // window.location.href = "http://qrcode.ayouayou.com/qrcode/temp/233.zip";
                  let a = document.createElement('a')
                  a.href = res.downloadPath;
                  a.click();
                }).catch(() => {
                  this.$message({
                    type: 'info',
                    message: '已取消'
                  });
                });
              }
          ).catch(() => {
            this.$message.warning('上传失败')
          })
          .then(() => {
            this.uploading = false
          })
    },
    beforeUpload(file) {
      if (!file) {
        this.$message.warning("请先选择文件")
        return false;
      }
      return true;
    },
    uploadImg() {
      if (!this.beforeUpload(this.form.file)) {
        return;
      }
      this.uploading = true
      console.log()
      return acct.uploadFile(this.form.file)
          .then(res => {
                console.log("请求成功" + res)
                console.log(res.content);
                this.resp = res.content;
              }
          ).catch(() => {
            this.$message.warning('上传失败')
          })
          .then(() => {
            this.uploading = false
          })
    },
    transform: function (type) {
      if (type === 1) {
        console.log(type)
      } else {
        console.log(type)
      }
      acct.change({
        type: type,
        content: this.content,
      })
          .then(res => {
            console.log("请求成功" + res)
            console.log(res.content);
          })
          .catch(this.requestError)
    },
    my_copy() {
      console.log("copy" + this.content);
      // acct.sectionList()
      //     // .then(() => {
      //     //   console.log("请求成功")
      //     //   // this.$message.success('密码重置成功')
      //     // })
      //     .then(res => {
      //       console.log("请求成功" + res)
      //       // this.status = this.$formatList(res.data['充值状态枚举'])
      //       // this.payChannel = this.$formatList(res.data['支付方式枚举'], 'value', 'label', false)
      //     })
      //     .catch(this.requestError)
      //     .finally(() => {
      //       console.log("finally")
      //       // this.loading = false
      //     })

      acct.change(this.content)
          .then(res => {
            console.log("请求成功" + res)
            console.log(res.content);

          })
          .catch(this.requestError)
    }

  }
}

// import FreeStar from "@/components/FreeStar";

// export default {
//   name: 'App',
//   components: {
//     FreeStar
//   }
// }


// new Vue({
//   el: '#app2',
//   data: {message: 'hello freestar'}
// })
// new Vue({
//   el: '#app',
//   data: {
//     msg: 'Hello freestar!'
//   }
// })
</script>

<style>

.textContent {
  width: 100%;
  height: 80%;
}

.btn {
  margin-left: 10%;
}

.btnContainer {
  /*text-align: center;*/
  text-align: center;
}

.flex-ac {
  display: flex;
  align-items: center;
}


.content {
  width: 70%;
  margin-left: 5%;
}

#head {
  text-align: -webkit-center;
}



#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  /*margin-top: 10px;*/
}
</style>
