export default {
  data() {
    return {
      query: this.initialQuery(),
      total: 0,
      list: [],
      loading: true,
      date: undefined,
      dateNoTime: undefined,
    }
  },
  watch: {
    date(v) {
      const arr = v || []
      this.query.startTime = arr[0]
      this.query.endTime = arr[1]
    },
    dateNoTime(v) {
      const arr = v || []
      this.query.startDate = arr[0]
      this.query.endDate = arr[1]
    },
  },
  methods: {
    requestList({data, total}, dataFormat) {
      this.total = Number(total)
      this.list = dataFormat ? dataFormat(data) : data
    },
    requestError(data) {
      // console.log("requestError normal" + data)
      this.$message.warning(data.msg)
    },
    initialQuery() {
      return {
        pageNo: 1,
        pageSize: 20,
      }
    },
    resetQuery() {
      this.dateNoTime = undefined
      this.date = undefined
      this.query = this.initialQuery()
      this.getList()
    },
    search() {
      this.query.pageNo = 1
      this.getList()
    },
    pageChange(page) {
      this.query.pageNo = page
      this.getList()
    },
  },
}
