<template>
  <div>
    <h1 id="head">欢迎来到浪星的小屋</h1>
    <el-row>

      <el-button type="primary" @click="go('/home')">乱码修复</el-button>
      <el-button type="primary" @click="go('/calculate')">还款计算器</el-button>
      <el-button type="primary" @click="go('/file')">文件上传</el-button>

    </el-row>

    <div class="botCenter">
      <!-- <p>&copy;Copyright - Medicio Theme. All rights reserved.</p> -->
      <a href="http://beian.miit.gov.cn/" target="view_window">浙ICP备20003888号-2</a>
    </div>

  </div>
</template>

<script>
export default {
  name: "mainFreestar",
  data() {
    return {}
  },
  computed: {
    path() {
      return this.$route.path
    },
  }, created() {
    console.log("main")
  },
  methods: {
    go(path) {
      console.log('222:'+path)
      this.$router.push(path)
    }
  }
}
</script>

<style scoped>
.botCenter {
  width: 100%;
  height: 35px;
  line-height: 35px;
  background: #ccc;
  position: fixed;
  bottom: 0;
  color: #000;
  left: 0;
  font-size: 14px;
  text-align: center;
}
</style>
