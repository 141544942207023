import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

// import role from './role'
// import auth from './auth'
import home from "../home";
import calculate from "../calculate";
import mainFreestar from "../mainFreestar";
import file from "../file";

const routes = [
    {
        path: '/',
        redirect: '/login',
    },
    {
        path: '/home',
        name: 'home',
        component: home,
        meta: { title: '乱码转换', hide: true, loginType: 'cms', loginTxt: '云力赛事' },
    },
    {
        path: '/file',
        name: 'file',
        component: file,
        meta: { title: '文件上传', hide: true, loginType: 'cms', loginTxt: '云力赛事' },
    },
    {
        path: '/calculate',
        name: 'calculate',
        component: calculate,
        meta: { title: '等额本息计算', hide: true, loginType: 'cms', loginTxt: '云力赛事' },
    },
    {
        path: '/mainFreestar',
        name: 'mainFreestar',
        component: mainFreestar,
        meta: { title: '主页', hide: true, loginType: 'cms', loginTxt: '云力赛事' },
    },
]


const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

// routeHook(router)

export default router

// export default new Vuex.Store({
//     state: {
//         menuFold: false,
//         ...role.state,
//         ...auth.state,
//     },
//     mutations: {
//         toggleFoldMenu(state, boolean = !state.menuFold) {
//             state.menuFold = boolean
//         },
//         ...role.mutations,
//         ...auth.mutations,
//     },
//     actions: {
//         toggleFoldMenu(context, boolean) {
//             context.commit('toggleFoldMenu', boolean)
//         },
//         ...role.actions,
//         ...auth.actions,
//     },
//
//     modules: {},
// })
