<template>
  <div>
    <div v-if="type === 'img'" :style="size" class="img-upload" :class="{ dashed: !imgFile.img }">
      <input class="upload-ipt" :class="`ipt-${id}`" type="file" accept="image/*" @change="selectImg">
      <i class="el-icon-plus" v-show="!imgFile.img"></i>
      <img v-show="imgFile.img" :src="imgFile.img" alt="">
    </div>
    <div v-if="type === 'file'" :style="size" class="file-upload">
      <input class="upload-ipt" :class="`ipt-${id}`" type="file" @change="selectFile">
      <el-input v-model="fileName" disabled></el-input>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: [String, File],
      default: '',
    },
    type: {
      type: String,
      default: 'file',
    },
    size: {
      type: String,
      default: '',
    },
  },
  created() {
    this.id = this._uid
  },
  data() {
    return {
      imgFile: {
        img: this.img,
      },
      id: '',
      fileName: '点击或拖拽选择文件',
    }
  },
  watch: {
    img(v) {
      if (typeof v !== 'object') {
        this.reset()
        this.imgFile.img = v
      }
    },
  },
  methods: {
    reset() {
      const ipt = document.querySelector(`.ipt-${this.id}`)
      ipt.value = ''
      this.imgFile = {}
      this.fileName = ''
    },
    selectFile() {
      const ipt = document.querySelector(`.file-upload .ipt-${this.id}`)
      const file = ipt.files[0]
      if (!file) return

      this.$emit('change', file)

      this.fileName = file.name
    },
    selectImg() {
      const ipt = document.querySelector(`.img-upload .ipt-${this.id}`)
      const file = ipt.files[0]
      if (!file) return

      const reader = new FileReader()
      const that = this
      reader.addEventListener('load', () => {
        that.imgFile = {file, img: reader.result}
        that.$emit('change', file)
      }, false)
      reader.readAsDataURL(file)
    },
  },
}
</script>

<style lang="scss" scoped>
.upload-ipt {
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.img-upload {
  width: 80px;
  height: 80px;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  &.dashed {
    border: 1px dashed #999;
    border-radius: 4px;
  }

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .el-icon-plus {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.file-upload {
  position: relative;

  .file-name {

  }
}
</style>
