import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueClipboard from 'vue-clipboard2'

import axios from 'axios'

console.log(process.env.NODE_ENV)

Vue.use(VueClipboard)
Vue.config.productionTip = false
Vue.use(ElementUI)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')

if (process.env.NODE_ENV !== 'development') {
  axios.defaults.baseURL = process.env.VUE_APP_HOST
  console.log("freestar" + axios.defaults.baseURL)
}

