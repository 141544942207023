import axios from 'axios'
// import store from '@/store'
// import request from "../mixins/request";
// import th from "element-ui/src/locale/lang/th";

const base = '/schoolfellow'

// 添加请求拦截器
axios.interceptors.request.use((config) => {
  console.log("拦截请求")
  // config.headers.Authorization = store.state.session
  return config
}, (err) => Promise.reject(err))

// 添加响应拦截器
axios.interceptors.response.use((config) => {
  console.log("拦截相应"+config);
  return config
}, (err) => Promise.reject(err))

const callback = (res) => {
  console.log("callback" + res.data)
  console.log(res.status)
  console.log(res.data.code)

  if (res.data.code === '00') {
    return res.data.data
  } else {
    // if (res.data.code === '015') {
    //     store.dispatch('loginOut')
    //     return Promise.reject(res.data)
    // }

    console.log("进来这里了")
    // this.$message.warning(res.msg);
    // this.requestError(res.data.data)
    return Promise.reject(res.data)
  }
}

export default {
  get(url, params) {
    return axios.get(base + url, {params}).then(callback)
  },
  post(url, params) {
    return axios.post(base + url, params).then(callback)
  },

  postT(url, params) {
    return axios.post("http://test.jynmed.com:8086/accidentReport/warningList", params).then(callback)
  },

  del(url, params) {
    return axios.delete(base + url, {params}).then(callback)
  },
  upload(url, file) {
    console.log(file);
    console.log('upload file');
    const forms = new FormData();
    forms.append('file', file)
    const configs = {
      headers: {'Content-Type': 'multipart/form-data'}
    };
    return axios.post(base + url, forms, configs).then(callback)
  },
}
