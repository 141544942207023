<template>

  <router-view></router-view>

</template>

<script>
// import acct from "@/api/acct";

export default {
  data() {
    return {
      socketPath:"ws://test.jynmed.com:8086/websocket/freestar",
      socket:""
    }
  },
  computed: {
    path() {
      return this.$route.path
    },
  }, created() {
    console.log("main");
    // acct.
    // acct.warningList("")
    //     .then(res => {
    //           console.log("请求成功" + res)
    //           // console.log(res.content);
    //           // this.resp = res.content;
    //         }
    //     );
    // console.log(this.grouth(100.2,200));
    // console.log(this.grouth(200,100.1));
    // console.log(this.grouth(-232323323.3,-3234234.4));
    // console.log(this.grouth(-2323433323.3,3234234.4));
    // console.log(this.grouth(0,2392.23242));
    this.$router.push('/mainFreestar');
  },
  methods: {
    init: function () {
      if(typeof(WebSocket) === "undefined"){
        alert("您的浏览器不支持socket")
      }else{
        // 实例化socket
        this.socket = new WebSocket(this.socketPath)
        // 监听socket连接
        this.socket.onopen = this.open
        // 监听socket错误信息
        this.socket.onerror = this.error
        // 监听socket消息
        this.socket.onmessage = this.getMessage
      }
    },
    open: function () {
      console.log("socket连接成功")
    },
    error: function () {
      console.log("连接错误")
    },
    getMessage: function (msg) {
      console.log(msg.data)
    },
    // send: function () {
    //   this.socket.send(params)
    // },
    close: function () {
      console.log("socket已经关闭")
    },
    grouth(first, second) {
      if (first === 0) {
        return 0;
      }
      if (first > 0) {
        return (second / first - 1) * 100;
      } else {
        return (1 - (second / first)) * 100;
      }
    }
  },
  destroyed () {
    // 销毁监听
    this.socket.onclose = this.close
  }
}
</script>

<style>
</style>
