<template>
  <div id="app">

    <h1 id="head">等额本息还款计算器</h1>
    <div class="margin_left">
      1：输入 本金、还款月数、月还款额→→→年利率
    </div>
    <div class="margin_left">
      2：输入 本金、还款月数、年利率→→→月还款额
    </div>
    <div class="margin_left">温馨提示：存款利息计算器的计算结果仅供参考，由此造成的风险自负。</div>
    <el-form :label-position="labelPosition" :rules="rules" ref="ruleForm" style="width: 20%;margin-left: 5%"
             label-width="100px"
             :model="formLabelAlign">
      <el-form-item label="本金/￥" prop="money">
        <el-input v-model="formLabelAlign.money"></el-input>
      </el-form-item>
      <el-form-item label="还款月数/月" prop="monthNum">
        <el-input v-model="formLabelAlign.monthNum"></el-input>
      </el-form-item>
      <el-form-item label="月还款额/￥" prop="monthMoney">
        <el-input v-model="formLabelAlign.monthMoney"></el-input>
      </el-form-item>
      <el-form-item label="年利率/%" prop="yearRate">
        <el-input v-model="formLabelAlign.yearRate"></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="submitForm()">计算</el-button>
        <el-button @click="resetForm()">重置</el-button>
      </el-form-item>

      <el-form-item label="月利率/%" prop="monthRate">
        <el-input v-model="formLabelAlign.monthRate"></el-input>
      </el-form-item>
      <el-form-item label="总利息/￥" prop="interest">
        <el-input v-model="formLabelAlign.interest"></el-input>
      </el-form-item>
      <el-form-item label="本息合计/￥" prop="totalMoney">
        <el-input v-model="formLabelAlign.totalMoney"></el-input>
      </el-form-item>
    </el-form>

  </div>


</template>

<script>
import acct from "./api/acct";
import request from "./mixins/request";

export default {
  name: "calculate",
  mixins: [request],
  data() {
    const checkNum = (rule, value, callback) => {
      setTimeout(() => {
        // console.log(value);
        if (!this.isNumber(value)) {
          callback(new Error('请输入数字值'));
        } else {
          callback();
        }
      }, 1000);
    };
    return {
      labelPosition: 'left',
      formLabelAlign: {
        interest: '',
        totalMoney: '',
        money: '',
        monthMoney: '',
        yearRate: '',
        monthRate: '',
        monthNum: ''
      },
      uploading: false,
      rules: {
        money: [
          {required: true, message: '请输入本金', trigger: 'blur'},
          {validator: checkNum, trigger: 'blur'}
        ],
        // monthMoney: [
        //   {required: false},
        //   {validator: checkNum, trigger: 'blur'}
        // ],
        // yearRate: [
        //   {required: false},
        //   {validator: checkNum, trigger: 'blur'}
        // ],
        monthNum: [
          {required: true, message: '请输入还款月数', trigger: 'blur'},
          {validator: checkNum, trigger: 'blur'}
        ],
      }
    };
  },
  methods: {
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    isNumber(str) {
      const reg = /^\d+(\.\d+)?$/;
      return reg.test(str)
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.formLabelAlign.monthMoney === '' && this.formLabelAlign.yearRate === '') {
            this.$message.error('月还款额、年利率必填其中一个');
          }
          if (this.formLabelAlign.monthMoney !== '') {
            console.log(this.formLabelAlign.monthMoney);
            if (!this.isNumber(this.formLabelAlign.monthMoney)) {
              this.$message.error('月还款额 无法转为数字值');
              return;
            }
            // this.formLabelAlign.yearRate = this.rateCalculator(this.formLabelAlign.money, this.formLabelAlign.month, this.formLabelAlign.monthMoney);
            acct.money({
              monthMoney: this.formLabelAlign.monthMoney,
              capital: this.formLabelAlign.money,
              monthNum: this.formLabelAlign.monthNum
            })
                .then(res => {
                      this.setObj(res)
                    }
                )
                // .catch(res => {
                //   this.$message.warning('上传失败1'+res.msg);
                // })
                .catch(this.requestError)
                .then(() => {
                  this.uploading = false
                })
          }
          if (this.formLabelAlign.yearRate !== '') {
            if (!this.isNumber(this.formLabelAlign.yearRate) && this.formLabelAlign.monthMoney === '') {
              this.$message.error('年利率 无法转为数字值');
              return;
            }
            // this.formLabelAlign.monthMoney = this.monthMoneyCalculator(this.formLabelAlign.money, this.formLabelAlign.month, this.formLabelAlign.yearRate);
            acct.money({
              capital: this.formLabelAlign.money,
              yearRate: this.formLabelAlign.yearRate,
              monthNum: this.formLabelAlign.monthNum
            })
                .then(res => {
                      this.setObj(res)
                    }
                )
            //     .catch(res => {
            //   this.$message.warning('上传失败2'+res.msg);
            // })
                .catch(this.requestError)
                .then(() => {
                  this.uploading = false
                })
          }
          // this.formLabelAlign.monthRate = (Number(this.formLabelAlign.yearRate) / 12.0).toFixed(2);
          // this.formLabelAlign.totalMoney = (Number(this.formLabelAlign.monthMoney) * Number(this.formLabelAlign.month)).toFixed(2);
          // this.formLabelAlign.interest = (Number(this.formLabelAlign.totalMoney) - Number(this.formLabelAlign.money)).toFixed(2);
          // console.log(obj)
          // this.formLabelAlign.monthRate = obj.monthRate;
          // this.formLabelAlign.totalMoney = obj.totalMoney;
          // this.formLabelAlign.interest = obj.totalMoney;

        }
      })
    },
    setObj(obj) {
      this.formLabelAlign.yearRate = obj.yearRate;
      this.formLabelAlign.monthMoney = obj.monthMoney;
      this.formLabelAlign.monthRate = obj.monthRate;
      this.formLabelAlign.totalMoney = obj.totalMoney;
      this.formLabelAlign.interest = obj.interest;
    },
    monthMoneyCalculator(totalLoan, monthNum, yearRate) {
      let monthRate = yearRate / 12.0;
      return (totalLoan * monthRate * Math.pow((1 + monthRate), monthNum)) / (Math.pow((1 + monthRate), monthNum) - 1);
    },
    /**
     * 等额本息：已知每月还款额，计算年利率.
     *
     * @param totalLoan 贷款总额(单位：元)
     * @param months    贷款期数(月数)
     * @param monthPay  每月还款额(元)
     * @return 年利率(%)
     */
    rateCalculator(totalLoan, months, monthPay) {
      let monthRate; //月利率
      let monthPayLoan = []
      //每期还的本金
      /*本算法采用二分法穷举第一月的还款本金来计算每月还款本金，计算出还的本金就可以得到月利率
       */
      let low = 0;
      let up = totalLoan / months;
      let isFinish = false;
      let j = 0;
      do {
        monthPayLoan[0] = (low + up) / 2;
        monthRate = (monthPay - monthPayLoan[0]) / totalLoan;
        let havePayLoan = 0;
        let calculateLoan = monthPayLoan[0];
        for (let i = 1; i < months; i++) {
          havePayLoan += monthPayLoan[i - 1];
          monthPayLoan[i] = monthPay - (totalLoan - havePayLoan) * monthRate;
          calculateLoan += monthPayLoan[i];
        }
        let calculate = Math.round(calculateLoan);
        let total = Math.round(totalLoan);
        if (calculate === total) {
          isFinish = true;
        } else if (calculate > total) {
          up = monthPayLoan[0];
        } else {
          low = monthPayLoan[0];
        }
        if (++j > 30) {
          isFinish = true;
          monthRate = -1;
        }
      } while (!isFinish);
      return monthRate * 12 * 100;
    }
  }
}
</script>

<style scoped>
.margin_left {
  margin-left: 5%;
  margin-bottom: 20px;
}
</style>

