import base from './base'

export default {
    rechargeList: (data) => base.get('/accttraderecharge/list', data),
    sectionList: (data) => base.get('/post/sectionList', data),
    change: (data) => base.post('/system/change', data),
    uploadFile: (file) => base.upload('/system/upload', file),
    uploadTempFile: (file) => base.upload('/system/uploadTemp', file),
    money: (data) => base.get('/system/money', data),
    warningList: (data) => base.postT('/accidentReport/warningList', data),
    // appss: (data) => base.postTs('/accidentReport/warningList', data),
}
